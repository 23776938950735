<template>
  <div :class="{'border-b': hasBorder}" class="border-dashed border-romanSilver mb-5 pb-5">
    <p class="font-semibold text-sm text-darkPurple mb-2">{{ title }}</p>
    <div class="flex justify-start items-start gap-5">
      <div class="w-44 h-32 rounded-md text-blueCrayola font-normal">
          <img class="w-44 h-32 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
          <div v-else class="h-32 border rounded-md flex justify-center items-center">
          <span style="font-size:60px">
            {{ $getInitials(`${employeeInfo.fname || ''} ${employeeInfo.lname || ''}`) }}
          </span>
          </div>
        </div>
      <div class="flex flex-col justify-start items-start gap-2 w-full">
        <div class="w-full flex flex-col justify-start items-start gap-2">
          <h4 class="font-bold text-lg leading-5 text-darkPurple">
            {{ employeeInfo.fname }} {{ employeeInfo.lname }}
          </h4>
          <div class="flex flex-col justify-start items-center w-full gap-0">
            <div class="flex justify-start items-start w-full">
              <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                Function:
              </p>
              <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
              {{ employeeInfo.orgFunction || '-' }}
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                Designation:
              </p>
              <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                {{ employeeInfo.designation || '-' }}
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                Job Level:
              </p>
              <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                {{ employeeInfo.level || '-' }}
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                Office Location:
              </p>
              <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                {{ employeeInfo.officeAddress || '-' }}
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                Reporting to
              </p>
              <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
               <span v-if="employeeInfo.userReportingTo">
                  {{ employeeInfo.userReportingTo.fname || '-' }} {{ employeeInfo.userReportingTo.lname || '-' }}
               </span>
               <span v-else-if="employeeInfo.reportsTo">
                  {{ employeeInfo.reportsTo.name || '-' }}
               </span>
               <span v-else>-</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      employeeInfo: {
        type: Object,
        default: () => {}
      },
      title: {
        type: String,
        default: 'Employee Details',
      },
      hasBorder: {
        type: Boolean,
        default: true,
      },
    }
  }
</script>

<style scoped>
._font-10 {
  font-size: 10px !important;
}
._text-10 {
  font-size: 10px !important;
}
</style>

